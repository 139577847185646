import store from "@/store/index";
import moment from "moment";

// ------------------------------------------------
// Get Collection ID Code and convert to JS numeric day
// ------------------------------------------------
const collectionDayToDayOfWeek = (collectionID) => {
    switch (collectionID) {
        case "100000000":
            return 1;
        case "100000001":
            return 2;
        case "100000002":
            return 3;
        case "100000003":
            return 4;
        case "100000004":
            return 5;
        case "100000005":
            return 6;
        case "100000006":
            return 0;
    }
    return false;
};

// ------------------------------------------------
// Get Collection ID Code and convert to JS numeric day
// ------------------------------------------------
const collectionDayToDayOfWeekName = (collectionID) => {
    switch (collectionID) {
        case "100000000":
            return "MO";
        case "100000001":
            return "TU";
        case "100000002":
            return "WE";
        case "100000003":
            return "TH";
        case "100000004":
            return "FR";
        case "100000005":
            return "SA";
        case "100000006":
            return "SU";
    }
    return false;
};

const getColor = (count) => {
    switch (count) {
        case 0:
            return {
                backgroundColor: "#00a19a",
                textColor: "#FFF",
            };
        case 1:
            return {
                backgroundColor: "#5D8BF4",
                textColor: "#FFF",
            };
        case 2:
            return {
                backgroundColor: "#ED8240",
                textColor: "#FFF",
            };
        case 3:
            return {
                backgroundColor: "#283046",
                textColor: "#FFF",
            };
        default:
            return {
                backgroundColor: "#666",
                textColor: "#FFF",
            };
    }
};

function compareTime(time1, time2) {
    return new Date(time1) >= new Date(time2); // true if time1 is later
}

// Get today's day in numeric e.g. Sunday 0
const getTheDayToday = () => {
    const d = new Date();
    let day = d.getDay();
    return day;
};

// Work out next collection date based on collection day and current day
const getNextCollection = (collectionDay) => {
    const nextCollectionDate = new Date();
    const todaysDate = new Date();
    nextCollectionDate.setDate(
        todaysDate.getDate() + ((7 + collectionDay - todaysDate.getDay()) % 7)
    );
    return nextCollectionDate;
};

// Get a year of collections from next collection day
const getWeeklyEvents = (date, productName, colors) => {
    const seedDate = new Date(date);
    seedDate.setHours(12);
    const today = new Date();
    let events = [];
    let compare = false;
    let nextCollectionDate = null;

    // Loop from Seed Date adding 1 week each iteration and check if its past todays date - this gets the next collection date
    for (var i = 0; i < 1000; i++) {
        nextCollectionDate = new Date(
            seedDate.getTime() + 7 * i * 24 * 60 * 60 * 1000
        );
        compare = compareTime(nextCollectionDate, today);
        if (compare === true) {
            break;
        }
    }

    let collectionDate = null;

    for (var i = 0; i < 52; i++) {
        if (i == 0) {
            collectionDate = nextCollectionDate;
        } else {
            collectionDate = new Date(
                nextCollectionDate.getTime() + 7 * i * 24 * 60 * 60 * 1000
            );
        }
        events[i] = {
            title: productName,
            date: moment(String(collectionDate)).format("YYYY-MM-DD"),
            backgroundColor: colors.backgroundColor,
            textColor: colors.textColor,
            borderColor: colors.backgroundColor,
        };
    }
    return { events: events, nextCollectionDate: nextCollectionDate };
};

// Get a year of collections from next collection day
const getFortnightlyEvents = (date, productName, colors) => {
    const seedDate = new Date(date);
    seedDate.setHours(12);
    const today = new Date();
    let events = [];
    let compare = false;
    let nextCollectionDate = null;

    // Loop from Seed Date adding 1 week each iteration and check if its past todays date - this gets the next collection date
    for (var i = 0; i < 1000; i++) {
        nextCollectionDate = new Date(
            seedDate.getTime() + 14 * i * 24 * 60 * 60 * 1000
        );
        compare = compareTime(nextCollectionDate, today);
        if (compare === true) {
            break;
        }
    }

    let collectionDate = null;

    for (var i = 0; i < 26; i++) {
        if (i == 0) {
            collectionDate = nextCollectionDate;
        } else {
            collectionDate = new Date(
                nextCollectionDate.getTime() + 14 * i * 24 * 60 * 60 * 1000
            );
        }
        events[i] = {
            title: productName,
            date: moment(String(collectionDate)).format("YYYY-MM-DD"),
            backgroundColor: colors.backgroundColor,
            textColor: colors.textColor,
            borderColor: colors.backgroundColor,
        };
    }
    return { events: events, nextCollectionDate: nextCollectionDate };
};

// Get a year of collections from next collection day
const getFourWeeklyEvents = (date, productName, colors) => {
    const seedDate = new Date(date);
    seedDate.setHours(12);
    const today = new Date();
    let events = [];
    let compare = false;
    let nextCollectionDate = null;

    // Loop from Seed Date adding 1 week each iteration and check if its past todays date - this gets the next collection date
    for (var i = 0; i < 1000; i++) {
        nextCollectionDate = new Date(
            seedDate.getTime() + 28 * i * 24 * 60 * 60 * 1000
        );
        compare = compareTime(nextCollectionDate, today);
        if (compare === true) {
            break;
        }
    }

    let collectionDate = null;

    for (var i = 0; i < 26; i++) {
        if (i == 0) {
            collectionDate = nextCollectionDate;
        } else {
            collectionDate = new Date(
                nextCollectionDate.getTime() + 28 * i * 24 * 60 * 60 * 1000
            );
        }
        events[i] = {
            title: productName,
            date: moment(String(collectionDate)).format("YYYY-MM-DD"),
            backgroundColor: colors.backgroundColor,
            textColor: colors.textColor,
            borderColor: colors.backgroundColor,
        };
    }
    return { events: events, nextCollectionDate: nextCollectionDate };
};

// Get a year of collections from next collection day
const getMonthlyEvents = (date, productName, colors) => {
    const seedDate = new Date(date);
    seedDate.setHours(12);
    const today = new Date();
    let events = [];
    let compare = false;
    let nextCollectionDate = null;
    const originalMonth = seedDate.getMonth();

    // Loop from Seed Date adding 1 week each iteration and check if its past todays date - this gets the next collection date
    for (var i = 1; i < 1000; i++) {
        //nextCollectionDate = new Date(seedDate.setMonth(originalMonth + i));
        nextCollectionDate = moment(seedDate).add(i, "months").format();
        compare = compareTime(nextCollectionDate, today);
        if (compare === true) {
            break;
        }
    }

    let collectionDate = null;

    for (var i = 0; i < 12; i++) {
        if (i == 0) {
            collectionDate = nextCollectionDate;
        } else {
            collectionDate = moment(nextCollectionDate).add(i, "months").format();
        }
        events[i] = {
            title: productName,
            date: moment(String(collectionDate)).format("YYYY-MM-DD"),
            backgroundColor: colors.backgroundColor,
            textColor: colors.textColor,
            borderColor: colors.backgroundColor,
        };
    }
    return { events: events, nextCollectionDate: nextCollectionDate };
};

// Get a year of collections from next collection day
const getmultiWeeklyEvents = (collectionDays, productName, colors) => {
    let events = [];
    let eventsTemp = [];
    let nextCollectionDate = null;
    let newCollectionDate = null;
    let prevCollectionDate = null;
    let collectionDate = null;

    for (var i = 0; i < collectionDays.length; i++) {
        let jsDayOfWeek = collectionDayToDayOfWeek(collectionDays[i]);
        nextCollectionDate = getNextCollection(jsDayOfWeek);
        if (compareTime(nextCollectionDate, prevCollectionDate)) {
            newCollectionDate = prevCollectionDate;
        } else {
            newCollectionDate = nextCollectionDate;
        }
        for (var x = 0; x < 52; x++) {
            if (x == 0) {
                collectionDate = nextCollectionDate;
            } else {
                collectionDate = moment(nextCollectionDate).add(x, "weeks").format();
            }
            eventsTemp[x] = {
                title: productName,
                date: moment(String(collectionDate)).format("YYYY-MM-DD"),
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
                borderColor: colors.backgroundColor,
            };
        }
        events.push(...eventsTemp);
        prevCollectionDate = nextCollectionDate;
    }
    return { events: events, nextCollectionDate: newCollectionDate };
};

// Get the next collection date based on collection day and current day
const nextCollections = (products) => {
    let productDates = [];
    let theEvents = [];

    for (var i = 0; i < products.length; i++) {
        let colors = getColor(i);
        if (products[i].CalculateDate === 1 && products[i].SeedDate != "") {
            if (products[i].CollectionFrq === "Weekly") {
                let collectionDays = products[i].CollectionDays.split(",");
                let calCollectionDays = [];
                collectionDays.forEach((element) => {
                    calCollectionDays.push(collectionDayToDayOfWeekName(element));
                });
                let weeklyEvents = getWeeklyEvents(
                    products[i].SeedDate,
                    products[i].ProductName,
                    colors
                );
                theEvents = theEvents.concat(weeklyEvents.events);

                productDates.push({
                    id: i,
                    name: products[i].ProductName,
                    date: moment(String(weeklyEvents.nextCollectionDate)).format(
                        "ddd, MMM Do, YYYY"
                    ),
                    calDate: moment(String(weeklyEvents.nextCollectionDate)).format(
                        "YYYY-MM-DD"
                    ),
                    frequency: "(" + products[i].CollectionFrq + ")",
                    calFrequency: "WEEKLY",
                    binQty: products[i].BinQty,
                    interval: 1,
                    byday: calCollectionDays,
                    seedDate: products[i].SeedDate,
                    weightLimit: products[i].WeightLimit,
                });
            } else if (products[i].CollectionFrq === "Fortnightly") {
                let collectionDays = products[i].CollectionDays.split(",");
                let calCollectionDays = [];
                collectionDays.forEach((element) => {
                    calCollectionDays.push(collectionDayToDayOfWeekName(element));
                });
                let fortnightlyEvents = getFortnightlyEvents(
                    products[i].SeedDate,
                    products[i].ProductName,
                    colors
                );
                theEvents = theEvents.concat(fortnightlyEvents.events);
                productDates.push({
                    id: i,
                    name: products[i].ProductName,
                    date: moment(String(fortnightlyEvents.nextCollectionDate)).format(
                        "ddd, MMM Do, YYYY"
                    ),
                    calDate: moment(String(fortnightlyEvents.nextCollectionDate)).format(
                        "YYYY-MM-DD"
                    ),
                    frequency: "(" + products[i].CollectionFrq + ")",
                    calFrequency: "WEEKLY",
                    binQty: products[i].BinQty,
                    interval: 2,
                    byday: calCollectionDays,
                    seedDate: products[i].SeedDate,
                    weightLimit: products[i].WeightLimit,
                });
            } else if (products[i].CollectionFrq === "1x 4 Weeks") {
                let collectionDays = products[i].CollectionDays.split(",");
                let calCollectionDays = [];
                collectionDays.forEach((element) => {
                    calCollectionDays.push(collectionDayToDayOfWeekName(element));
                });
                let fourweeklyEvents = getFourWeeklyEvents(
                    products[i].SeedDate,
                    products[i].ProductName,
                    colors
                );
                theEvents = theEvents.concat(fourweeklyEvents.events);
                productDates.push({
                    id: i,
                    name: products[i].ProductName,
                    date: moment(String(fourweeklyEvents.nextCollectionDate)).format(
                        "ddd, MMM Do, YYYY"
                    ),
                    calDate: moment(String(fourweeklyEvents.nextCollectionDate)).format(
                        "YYYY-MM-DD"
                    ),
                    frequency: "(" + products[i].CollectionFrq + ")",
                    calFrequency: "WEEKLY",
                    binQty: products[i].BinQty,
                    interval: 4,
                    byday: calCollectionDays,
                    seedDate: products[i].SeedDate,
                    weightLimit: products[i].WeightLimit,
                });
            } else if (
                products[i].CollectionFrq === "2x weekly" ||
                products[i].CollectionFrq === "3x weekly" ||
                products[i].CollectionFrq === "4x weekly" ||
                products[i].CollectionFrq === "5x weekly" ||
                products[i].CollectionFrq === "6x weekly" ||
                products[i].CollectionFrq === "7x weekly" ||
                products[i].CollectionFrq === "2x Weekly" ||
                products[i].CollectionFrq === "3x Weekly" ||
                products[i].CollectionFrq === "4x Weekly" ||
                products[i].CollectionFrq === "5x Weekly" ||
                products[i].CollectionFrq === "6x Weekly" ||
                products[i].CollectionFrq === "7x Weekly"
            ) {
                let collectionDays = products[i].CollectionDays.split(",");
                let calCollectionDays = [];
                collectionDays.forEach((element) => {
                    calCollectionDays.push(collectionDayToDayOfWeekName(element));
                });
                let multiWeeklyEvents = getmultiWeeklyEvents(
                    collectionDays,
                    products[i].ProductName,
                    colors
                );
                theEvents = theEvents.concat(multiWeeklyEvents.events);
                productDates.push({
                    id: i,
                    name: products[i].ProductName,
                    date: moment(String(multiWeeklyEvents.nextCollectionDate)).format(
                        "ddd, MMM Do, YYYY"
                    ),
                    calDate: moment(String(multiWeeklyEvents.nextCollectionDate)).format(
                        "YYYY-MM-DD"
                    ),
                    frequency: "(" + products[i].CollectionFrq + ")",
                    calFrequency: "WEEKLY",
                    binQty: products[i].BinQty,
                    interval: 1,
                    byday: calCollectionDays,
                    seedDate: products[i].SeedDate,
                    weightLimit: products[i].WeightLimit,
                });
            } else if (products[i].CollectionFrq === "Monthly") {
                let collectionDays = products[i].CollectionDays.split(",");
                let calCollectionDays = [];
                collectionDays.forEach((element) => {
                    calCollectionDays.push(collectionDayToDayOfWeekName(element));
                });
                let monthlyEvents = getMonthlyEvents(
                    products[i].SeedDate,
                    products[i].ProductName,
                    colors
                );
                theEvents = theEvents.concat(monthlyEvents.events);

                productDates.push({
                    id: i,
                    name: products[i].ProductName,
                    date: moment(String(monthlyEvents.nextCollectionDate)).format(
                        "ddd, MMM Do, YYYY"
                    ),
                    calDate: moment(String(monthlyEvents.nextCollectionDate)).format(
                        "YYYY-MM-DD"
                    ),
                    frequency: "(" + products[i].CollectionFrq + ")",
                    calFrequency: "WEEKLY",
                    binQty: products[i].BinQty,
                    seedDate: products[i].SeedDate,
                    weightLimit: products[i].WeightLimit,
                });
            }
        } else if (products[i].CalculateDate === 1 && products[i].SeedDate === "") {
            let createdOnDate = null;
            let advisedDelDate = null;
            let confirmedDelDate = null;
            let firstCollectionDate = null;

            if (products[i].CreatedOn) {
                createdOnDate = moment(String(products[i].CreatedOn)).format(
                    "ddd, MMM Do, YYYY"
                );
            }
            if (products[i].AdvisedDelDate) {
                advisedDelDate = moment(String(products[i].AdvisedDelDate)).format(
                    "ddd, MMM Do, YYYY"
                );
            }
            if (products[i].ConfirmedDelDate) {
                confirmedDelDate = moment(String(products[i].ConfirmedDelDate)).format(
                    "ddd, MMM Do, YYYY"
                );
            }
            if (products[i].FirstCollectionDate) {
                firstCollectionDate = moment(
                    String(products[i].FirstCollectionDate)
                ).format("ddd, MMM Do, YYYY");
            }

            productDates.push({
                id: i,
                inProgress: true,
                name: products[i].ProductName,
                date: "",
                createdOnDate: createdOnDate,
                advisedDelDate: advisedDelDate,
                confirmedDelDate: confirmedDelDate,
                firstCollectionDate: firstCollectionDate,
                frequency: "(" + products[i].CollectionFrq + ")",
                seedDate: products[i].SeedDate,
                weightLimit: products[i].WeightLimit,
            });
        } else {
            productDates.push({
                id: i,
                name: products[i].ProductName,
                date: "",
                frequency: "(" + products[i].CollectionFrq + ")",
                seedDate: products[i].SeedDate,
                weightLimit: products[i].WeightLimit,
            });
        }
    }

    store.commit("app/UPDATE_SELECTED_PREMISE_NEXT_COLLECTIONS", productDates);
    localStorage.setItem(
        "selectedPremiseNextCollections",
        JSON.stringify(productDates)
    );

    store.commit("app/UPDATE_SELECTED_PREMISE_COLLECTIONS", theEvents);
    localStorage.setItem("selectedPremiseCollections", JSON.stringify(theEvents));

    return theEvents;
};

export default { nextCollections, getColor };