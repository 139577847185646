<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- Brand logo-->
          <b-link class="brand-logo mb-2">
            <b-img fluid :src="logoImg" alt="Login V2" />
          </b-link>
          <!-- /Brand logo-->
          <b-card-title class="mb-1 font-weight-bold" title-tag="h3">
            Welcome to our Customer Portal
          </b-card-title>
          <b-card-text class="mb-2" v-if="!error">
            Please sign-in to your account
          </b-card-text>
          <b-card-text class="mb-2 text-danger" v-else>
            There was a problem with your login. Please check your email and
            access code are correct.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Access Code</label>
                  <b-link to="forgot-access-code">
                    <small>Forgot your access code?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="e.g. 5G5252$"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                id="login-btn"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
            <div class="loading login-page" id="login-page-loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="sideImg" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>

    <div class="d-lg-none" id="add-to-ios">
      <a
        href="#"
        class="add-to-ios-btn"
        style="position: relative"
        @click="appInstructionsToggle"
        ><img
          src="share-btn.png"
          alt="Share Icon"
          style="position: absolute; left: 20px; top: 10px; width: 20px"
        />
        Add App to Homescreen</a
      >
      <div class="instructions py-2" style="display: none" id="instructions">
        <p class="text-center">
          This website has app functionality. Add it to your iPhone home screen
          to use it in full screen and to launch quickly. Android users should
          use the in built App Install feature.
          <img src="add-to-ios-instructions.png" alt="Add App to Home Screen" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import getCollections from "./getCollections";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      sideImg: require("@/assets/images/bw/fel.png"),
      logoImg: require("@/assets/images/bw/bws-logo-blue.jpg"),
      password: "",
      userEmail: "",
      error: null,

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    appInstructionsToggle() {
      const elem = document.getElementById("instructions");
      if (elem.style.display === "none") {
        elem.style.display = "block";
      } else {
        elem.style.display = "none";
      }
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          // Fetch Customer Details
          const loginButton = document.getElementById("login-btn");
          const loginPageLoading =
            document.getElementById("login-page-loading");
          loginButton.innerText = "Signing you in...";
          loginPageLoading.style.display = "block";

          fetch(
            "https://prod-21.uksouth.logic.azure.com:443/workflows/61ae2228a9534560acd66f8716244fc9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=yztRmtHvrkDovlfgEFhag_oXV65QniqhRO4ARZ1UAMc",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                AccUID: this.userEmail,
                AccKey: this.password,
              }),
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error("HTTP status " + response.status);
              }
              return response.json();
            })
            .then((data) => {
              const userData = {
                id: 1,
                firstName: data.FirstName,
                secondName: data.LastName,
                fullName: data.FirstName + " " + data.LastName,
                avatar: require("@/assets/images/avatars/2-small.png"),
                email: this.userEmail,
                role: "admin",
                userLevel: data.UserLevel,
                ability: [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ],
                AccIds: data.AccIds,
              };

              loginButton.innerText = "Gathering your accounts...";

              fetch(
                "https://prod-09.uksouth.logic.azure.com:443/workflows/56a17d78d83947b5810a929e15f8cec5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=T27RaUcnIpOB8_MYvRHGdfFUKHsiqofmzpAxUz-RYVE",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    caccid: userData.AccIds[0].MachineID,
                  }),
                }
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                  }
                  return response.json();
                })
                .then((data) => {
                  store.commit(
                    "app/UPDATE_SELECTED_PREMISE_NAME",
                    userData.AccIds[0].AccName
                  );
                  localStorage.setItem(
                    "selectedPremiseName",
                    userData.AccIds[0].AccName
                  );

                  store.commit(
                    "app/UPDATE_SELECTED_PREMISE_ID",
                    userData.AccIds[0].MachineID
                  );
                  localStorage.setItem(
                    "selectedPremiseID",
                    userData.AccIds[0].MachineID
                  );

                  store.commit(
                    "app/UPDATE_SELECTED_USER_LEVELS",
                    userData.userLevel
                  );
                  localStorage.setItem(
                    "selectedUserLevels",
                    userData.userLevel
                  );

                  store.commit(
                    "app/UPDATE_SELECTED_PREMISE_HUMAN_ID",
                    userData.AccIds[0].HumanID
                  );
                  localStorage.setItem(
                    "selectedPremiseHumanID",
                    userData.AccIds[0].HumanID
                  );

                  let strippedAddress = data.AccAdd1.replace(
                    new RegExp("\r\n", "g"),
                    ", "
                  );
                  store.commit(
                    "app/UPDATE_SELECTED_PREMISE_ADDRESS",
                    strippedAddress
                  );
                  localStorage.setItem(
                    "selectedPremiseAddress",
                    strippedAddress
                  );

                  store.commit(
                    "app/UPDATE_SELECTED_PREMISE_PRODUCT_LIST",
                    data.ProductsList
                  );
                  localStorage.setItem(
                    "selectedPremiseProductList",
                    JSON.stringify(data.ProductsList)
                  );

                  store.commit(
                    "app/UPDATE_SELECTED_PREMISE_LAST_LOGIN",
                    data.LastLog
                  );
                  localStorage.setItem(
                    "selectedPremiseLastLogin",
                    JSON.stringify(data.LastLog)
                  );

                  localStorage.setItem("lastLoginTime", new Date());

                  const nextCollection = getCollections.nextCollections(
                    data.ProductsList
                  );

                  document.getElementById("add-to-ios").style.display = "none";

                  // Logged in now re-direct to dashboard
                  this.$ability.update(userData.ability);
                  localStorage.setItem("userData", JSON.stringify(userData));

                  //location.reload();
                  this.$router.replace("/");
                });
            })
            .catch((error) => {
              this.error = true;
              loginButton.innerText = "Sign in";
              loginPageLoading.style.display = "none";
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
